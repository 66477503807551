.auth0-lock {
  font-family: 'Proxima Nova' !important;
  min-width: 296px;
}

.auth0-lock-center {
  padding: 0 !important;
}

.auth0-lock-widget {
  height: inherit;
  width: 100% !important;
  max-width: 360px !important;
}

.auth0-lock-cred-pane-internal-wrapper {
  height: fit-content !important;
}

.auth0-lock-content-wrapper {
  flex-grow: initial !important;
}

.auth0-lock-form {
  padding: 20px 0 0 0 !important;
}

.auth0-lock-tabs-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.auth0-lock-header {
  height: 50px;
}

.auth0-lock-back-button {
  height: 16px !important;
  width: 16px !important;
}

.auth0-lock-cred-pane,
.auth0-lock-tabs,
.auth0-lock-header,
.auth0-lock-header-bg,
.auth0-lock-header-bg-solid,
.auth0-lock-header-bg-blur,
.auth0-lock-terms {
  background-color: transparent !important;
}

.auth0-lock-tabs li a {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  font-weight: 700 !important;
}

li.auth0-lock-tabs-current span {
  color: #37358a !important;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  font-weight: 700 !important;
}

li.auth0-lock-tabs-current {
  border-bottom: 1px solid #37358a;
  box-shadow: none !important;
}

.auth0-lock-input {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.auth0-label-submit {
  font-weight: bold;
}

.auth0-lock-input-block:last-child {
  margin-bottom: 16px !important;
}

.icon-text {
  display: none !important;
}

.auth0-lock-input-checkbox,
.auth0-lock-terms {
  color: #000 !important;
  margin-top: 24px;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.25px;
  padding: 0 !important;
  text-align: left !important;
  font-weight: 400;
}

.auth0-lock-terms {
  margin-bottom: 24px;
}

.auth0-lock-terms a {
  color: #409af9 !important;
  line-height: 24px !important;
  letter-spacing: 0.1px;
}
.auth0-lock-alternative {
  margin-bottom: 96px !important;
}

.auth0-lock-submit {
  border-radius: 6px !important;
}

.auth0-lock-alternative-link {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #57a5f9 !important;
}

.auth0-global-message {
  font-size: 12px !important;
  text-transform: none !important;
}

.auth0-global-message-success {
  background-color: #93db96 !important;
  color: #000000de !important;
}

.auth0-global-message-error {
  background-color: #f88e8e !important;
  color: #000000de !important;
}
